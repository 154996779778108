import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';

import Nav from '../components/Nav';
import Layout from '../components/Layout';
import {FONT_FAMILY, PRIMARY_COLOR} from '../constants';
import Wave from '../ui/Wave';
import Button from '../ui/Button';
import Buttons from '../ui/Buttons';
import {homepageRoute} from '../routes';
import EnvData from '../components/EnvData';

// markup
export default function NotFoundPage() {
	return (
		<EnvData>
			<Layout title="Not found">
				<Nav background={PRIMARY_COLOR} />
				<Wrapper>
					<div>
						<span>Error 404</span>
						<small>Stranica nije pronađena.</small>
					</div>
				</Wrapper>
				<Wave height={110} orientation="below" />
				<Buttons textAlign="center">
					<Button as={Link} to={homepageRoute()}>
						Početna stranica
					</Button>
				</Buttons>
			</Layout>
		</EnvData>
	);
}

const Wrapper = styled.div`
	display: flex;
	background: ${PRIMARY_COLOR};
	height: 200px;
	align-items: center;

	> div {
		display: block;
		width: 100%;
		text-align: center;
		box-sizing: border-box;
		padding: 0 2rem;

		> span,
		> small {
			display: block;
			width: 100%;
			max-width: 800px;
			margin: 1rem auto;
			text-align: center;
			font-family: ${FONT_FAMILY};
			font-weight: 700;
			font-size: 3.5rem;
			color: #ffffff;
		}

		> small {
			font-size: 1.5rem;

			&:first-of-type {
				margin-top: 2.5rem;
			}
		}
	}
`;
